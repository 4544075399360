import React from "react";
import "./WaitList.css";

import Logo from "../../images/Logo15.png";

const WaitListNavbar = () => {
  return (
    <div>
      {/* <!-- As a link --> */}
      <nav className="navbar navbar-light bg-light">
        <div className="container-fluid">
          <div className="ripple">
            <a className="navbar-brand" href="/">
              <img src={Logo} alt="Logo avatar" />
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default WaitListNavbar;
