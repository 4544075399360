import React, { useState } from "react";

import "./WaitList.css";
import GooglePlay from "../../images/google2.png";

import { addDoc, collection } from "firebase/firestore";
import { db } from "../../lib/firebase.config";

const WaitList = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [agree, setAgree] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [loading, setLoading] = useState(false);

  const collectionRef = collection(db, "users");

  const createUser = async (e) => {
    e.preventDefault();
    if (firstName === "" || lastName === "" || email === "") {
      alert("Please fill all fields!");
    } else {
      setLoading(true);
      await addDoc(collectionRef, { firstName, lastName, email }).then(
        (data) => {
          console.log(data);
          setShowForm(false);
        }
      );
      setFirstName("");
      setLastName("");
      setEmail("");
    }
  };

  const handleAgreement = () => {
    return !agree ? setAgree(true) : setAgree(false);
  };
  console.log(agree);

  return (
    <div className="wait__list">
      <div className="main__waitlist">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
          <h1>ROHIPAY.</h1>
        <p>
        The first 3 in one payment platform. Send money, receive payment fast
          and spend money without worries. You can sign up for Personal Account,
          Business Account & Merchant Account
        </p>
        <div className="mainList__actions">
          <button>
            <i className="fab fa-apple"></i>{" "}
            <div>
              <span>Pre-order On</span> <br /> <h5>App Store</h5>{" "}
            </div>
          </button>
          <button>
            <img src={GooglePlay} alt="Google Play Avatar" />
            <div>
              <span>Pre-order On</span> <br /> <h5>Google Play</h5>
            </div>
          </button>
        </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
          <div className="form__waitlist">
        {showForm ? (
          <div>
            <h3>Join the Waiting List!</h3>
            <form method="POST" name="contactForm">
              {/* Form group */}
              <input type="hidden" name="form-name" value="contactForm" />
              <div className="dual__input">
                <div className="form__group">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
                <div className="form__group">
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    required
                  />
                </div>
              </div>
              {/* Form group */}
              <div className="form__group">
                <input
                  type="email"
                  name="emailAddress"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="accept__terms">
                <input
                  type="checkbox"
                  name="agree"
                  value={agree}
                  onChange={handleAgreement}
                />
                <span>
                  I agree to ROHIPAY terms of service & privacy policy. I also
                  agree to receive emails from ROHIPAY and understand that I may
                  opt out of ROHIPAY’s subscription at any time.
                </span>
              </div>
              <div className="button__section">
                <button
                  onClick={createUser}
                  className={!agree ? "disable" : ""}
                  disabled={!agree}
                >
                  {!loading ? "Sign Up" : "Loading"}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="successfully">
            <h3>Registration Successful!</h3>
            <p>
              Thank you for signing up for the early waitlist. We will let you
              know when we launch. Kindly follow us on our social media
              platforms to keep track of our activities.
            </p>
          </div>
        )}
      </div>
          </div>
        </div>
        
      </div>
      
    </div>
  );
};

export default WaitList;
