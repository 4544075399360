import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyANknyWIi1iSMesJVp3OKqZXR2tk-54uQ8",
  authDomain: "rohipay-waitlist.firebaseapp.com",
  projectId: "rohipay-waitlist",
  storageBucket: "rohipay-waitlist.appspot.com",
  messagingSenderId: "182137889072",
  appId: "1:182137889072:web:b930b6b7e695dcc32ba8f5",
  //   eslint-disable-next-line
  measurementId: "${config.measurementId}",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
