import "./App.css";
import WaitList from "./components/WaitList/WaitList";
import WaitListNavbar from "./components/WaitList/WaitListNavbar";

function App() {
  return (
    <div className="App">
      <WaitListNavbar />
      <WaitList />
    </div>
  );
}

export default App;
